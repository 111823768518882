.coming-soon {
    text-align: center;
  }
  img {
    height: 400px;
    margin-top: 200px;
  }
  body{
    background: #fff;
    
  }